import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import React, { FC } from 'react';

import { useIsomorphicLayoutEffect } from '../../hooks/useIsomorphicLayoutEffect';
import { Country } from '../../services/types';
import { setDYContext } from '../../utils/dynamicYield';
import { isDev, s3 } from '../../utils/env';
import { Tracking } from '../elements/tracking';

type PageProps = {
  title: string;
  country: Country;
  description?: string | null;
  canonical?: string | null;
  alternateUK?: string | null;
  alternateUS?: string | null;
  sharingImage?: string | null;
  cookieBanner?: boolean;
  children?: React.ReactNode;
};

export const AppLayout: FC<PageProps> = ({
  title,
  country,
  description,
  canonical,
  alternateUK,
  alternateUS,
  sharingImage,
  cookieBanner,
  children,
}) => {
  const loc = useRouter();
  const metaImage =
    s3 +
    (sharingImage
      ? sharingImage
      : 'uploads/images/cp/day/tg-cpa-bay-day-adult-group-iRjQJcTx-2.jpg');

  useIsomorphicLayoutEffect(() => {
    if (!isDev) {
      window.requestAnimationFrame(() => {
        const timer = setTimeout(() => {
          setDYContext(loc.asPath === '/us/' ? 'HOMEPAGE' : 'OTHER', loc.asPath);
        }, 500);
        return (): void => clearTimeout(timer);
      });
    }
  }, [loc.asPath]);

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     const timer = setTimeout(() => {
  //       setDYContext(loc.asPath === '/us/' ? 'HOMEPAGE' : 'OTHER', loc.asPath);
  //     }, 150);
  //     return (): void => clearTimeout(timer);
  //   }
  // }, [loc.asPath]);

  // useEffect(() => {
  //   if (typeof window !== 'undefined' && window.DY) {
  //     window.DY.API('page.update');
  //   }
  // }, []);

  // useEffect(() => {
  //   // Ensure DY is loaded
  //   if (window.DY) {
  //     // Re-trigger Dynamic Yield
  //     window.DY.API('event', {
  //       name: 'pageLoad',
  //       data: {},
  //     });
  //   }
  // }, []);

  return (
    <>
      <Head>
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <title>{title}</title>
        {description && <meta name="description" key="description" content={description} />}
        {canonical && <link rel="canonical" key="canonical" href={canonical} />}
        {alternateUS && <link rel="alternate" hrefLang="en-us" href={alternateUS} />}
        {alternateUK && <link rel="alternate" hrefLang="en-gb" href={alternateUK} />}
        <link rel="publisher" href="https://plus.google.com/u/0/112854098343159501899" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {canonical && <meta itemProp="url" content={canonical} />}
        {country == 'uk' && (
          <>
            <meta
              name="google-site-verification"
              content="ZfVQJBNSRkXT3ctNSMeaVj7LoivsL7IYZgwkcVy3O8Y"
            />
          </>
        )}
        <meta itemProp="name headline" content={title} />
        <meta itemProp="image" content={metaImage} />
        {description && <meta itemProp="description" content={description} />}
        <link rel="icon" href="https://topgolf.com/favicon.ico" sizes="48x48" />
        <link rel="icon" href="https://topgolf.com/favicon.svg" sizes="any" type="image/svg+xml" />
        <link rel="apple-touch-icon" href={s3 + 'apple-touch-icon-cpa.png?v=2023'} />
        <link rel="manifest" href="https://topgolf.com/site.webmanifest" />
        <link rel="mask-icon" href={s3 + 'safari-pinned-tab.svg?v=2019.01.02'} color="#050786" />
        <meta name="msapplication-TileColor" content="#050786" />
        <meta name="theme-color" content="#050786" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        {description && <meta name="twitter:description" content={description} />}
        <meta name="twitter:image:src" content={metaImage} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Topgolf" />
        {canonical && <meta property="og:url" content={canonical} />}
        <meta property="og:image" content={metaImage} />
        <meta property="og:title" content={title} />
        {description && <meta property="og:description" content={description} />}
        <meta property="fb:app_id" content="190580597660841" />
        <link rel={'preconnect'} href={'https://s3.topgolf.com'} />
        <link rel={'dns-prefetch'} href={'https://s3.topgolf.com'} />
        <link rel={'preconnect'} href={'https://impx.topgolf.com'} />
        <link rel={'dns-prefetch'} href={'https://impx.topgolf.com'} />
      </Head>
      {/* <Script
        type="module"
        src="https://sierra.chat/agent/VO5RQzQBlcVkf_8BlcVok0fRcz5nb6cYcyoRKHcRMIA/embed"
      /> */}
      <Script
        id="sierra-config"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            var sierraConfig = {
              display: "corner"
            };
          `,
        }}
      />
      {children}
      <Tracking cookieBanner={cookieBanner} country={country} />
    </>
  );
};
