import { axios } from '../utils/axios';
import { getExtension } from '../utils/img';

export type ImgProxyOptions = {
  fit?: string;
  width?: number;
  height?: number;
  gravity?: string;
  crop?: string;
  format?: string;
  quality?: number;
  fwd?: boolean;
};

type ImgProxyRequest = {
  path: string;
  options?: ImgProxyOptions;
};

export type ImgProxyResponse = {
  url: string;
  path: string;
};

export const getImgProxyFwdURL = (path: string, options: ImgProxyOptions): string => {
  let url = process.env.NEXT_PUBLIC_API_URL + 'urls/image?fwd=1';
  if (path) {
    url += `&path=${encodeURIComponent(path)}`;
  }
  if (options) {
    if (options.fit) {
      url += `&fit=${encodeURIComponent(options.fit)}`;
    }
    if (options.width) {
      url += `&width=${encodeURIComponent(options.width)}`;
    }
    if (options.height) {
      url += `&height=${encodeURIComponent(options.height)}`;
    }
    if (options.gravity) {
      url += `&gravity=${encodeURIComponent(options.gravity)}`;
    }
    if (options.crop) {
      url += `&crop=${encodeURIComponent(options.crop)}`;
    }
    if (options.format) {
      url += `&format=${encodeURIComponent(options.format)}`;
    }
    if (options.quality) {
      url += `&quality=${encodeURIComponent(options.quality)}`;
    }
  }
  return url;
};

export const getImgProxyURL = async ({
  path,
  options,
}: ImgProxyRequest): Promise<ImgProxyResponse> => {
  // check if string is pointing to s3.topgolf.com
  // if so, use the imgproxy service
  if (path.includes('s3.topgolf.com') && !['gif', 'svg'].includes(getExtension(path))) {
    const response = await axios.get<ImgProxyResponse>('urls/image', {
      params: {
        path: path,
        fit: options?.fit,
        width: options?.width,
        height: options?.height,
        gravity: options?.gravity,
        crop: options?.crop,
        format: options?.format || 'png',
        quality: options?.quality || 70,
        fwd: options?.fwd || false,
      },
    });
    return response.data;
  } else {
    // otherwise, just return the original path
    return { url: path, path: path };
  }
};
