import classNames from 'classnames';
import { FC } from 'react';

import { useUserContext } from '../../providers/userProvider';
import { Country } from '../../services/types';
import { ExternalLink } from '../elements/content';
import { Img } from '../elements/img';
import { LocationLink } from '../locationLink';

type FooterProps = {
  country: Country;
};
export const Footer: FC<FooterProps> = ({ country }) => {
  const { theme, setTheme } = useUserContext();
  const navigation = {
    play: [
      { name: 'Plan a Visit', href: '/' + country + '/plan-a-visit/' },
      { name: 'How to Play', href: '/' + country + '/experience/' },
      { name: 'Our Games', href: '/' + country + '/play/games/' },
      { name: 'Promotions', href: '/' + country + '/promo/' },
      { name: 'Golf Lessons', href: '/' + country + '/play/golf-lessons/' },
      {
        name: 'Shop Clubs & Gear',
        href: 'https://shop.topgolf.com/?utm_source=tg_site&utm_medium=tg_site_links&utm_campaign=none&utm_ad group=none&utm_keyword=none&utm_tactic=sitelink&utm_metric=none&utm_placement=come-play&utm_category=clubs&utm_landing=tg_shop_home&utm_content=sitelink&utm_promotion=none&utm_audience=none&utm_cdp=none&utm_team=cgi',
        display: country == 'us' ? true : false,
        newWindow: true,
      },
      {
        name: 'Player Code of Conduct',
        href: '/' + country + '/play-safely/',
        display: country == 'uk' ? true : false,
      },
      { name: 'Contact Us', href: '/' + country + '/company/contact-us/' },
    ],
    company: [
      {
        name: 'Careers',
        href: country == 'uk' ? '/uk/company/careers/' : 'https://careers.topgolf.com/',
        newWindow: country == 'us' ? true : false,
        external: country == 'us' ? true : false,
      },
      { name: 'Driving for Good', href: '/' + country + '/driving-for-good/' },
      { name: 'International', href: '/' + country + '/international/' },
      { name: 'Leadership', href: '/us/company/leadership/' },
      {
        name: 'Press Room',
        href: 'https://press.topgolf.com/',
        newWindow: true,
        external: true,
      },
      {
        name: 'For Vendors',
        href: '/us/company/vendors/',
        display: country == 'us' ? true : false,
      },
      {
        name: 'Stories',
        href: '/uk/blog/',
        display: country == 'uk' ? true : false,
      },
    ],
    legal: [
      { name: 'Web Accessibility', href: '/' + country + '/company/web-accessibility/' },
      { name: 'Terms & Conditions', href: '/' + country + '/company/terms-and-conditions/' },
      { name: 'Scam Awareness', href: '/' + country + '/company/scam-awareness/' },
      {
        name: country == 'us' ? 'Privacy, Ad & Cookie Policies' : 'Privacy',
        href: '/' + country + '/company/privacy-policy/',
      },
      {
        name: 'Your Privacy Choices',
        href:
          country == 'us'
            ? 'https://privacyportal.onetrust.com/webform/e35618a4-0a94-48f8-bb8e-00c8b0f8a3a2/8c305244-3603-49b1-ab59-e6885e8df7be'
            : 'https://privacyportal.onetrust.com/webform/e35618a4-0a94-48f8-bb8e-00c8b0f8a3a2/03a1d4a6-a69c-430c-80dc-c6097b3e6925',
        newWindow: true,
        external: true,
      },
    ],
    legalUK: [
      { name: 'Sustainability', href: 'https://www.topgolfcallawaybrands.com/sustainability' },
      {
        name: 'Modern Slavery Statement',
        href: 'https://www.topgolfcallawaybrands.com/static-files/b6332661-1231-411c-a1c0-d217acf68efe',
      },
      {
        name: 'Supplier Code of Conduct',
        href: 'https://www.topgolfcallawaybrands.com/static-files/7712a1f4-dd2f-4e40-9d77-815d71e0820c',
      },
      { name: 'ID Policy', href: '/uk/company/id-policy/' },
      { name: 'Gender Pay Gap', href: '/uk/company/gender-pay-gap/' },
      {
        name: 'Accessibility Pass',
        href: '/uk/fast-track-accessibility/',
        newWindow: false,
        external: false,
      },
    ],
  };
  return (
    <>
      <footer className="bg-white-blue dark:bg-brand-navy-light" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="container mx-auto px-4 py-8 md:p-8 lg:px-16 lg:pt-16 lg:pb-8">
          <div className="md:hidden">
            <div className="no-underline flex items-center justify-center mx-auto mb-8 space-x-2">
              <a
                className="appstore-badge appstore-badge--ios rounded-md h-10 w-auto border-none"
                href="https://apps.apple.com/us/app/topgolf/id646322524?ls=1">
                <span className="sr-only">App Store</span>
              </a>
              <a
                className="appstore-badge appstore-badge--google rounded-md h-10 w-auto border-none"
                href="https://play.google.com/store/apps/details?id=com.topgolf.mobile">
                <span className="sr-only">Google Play</span>
              </a>
            </div>
          </div>
          <div
            className={classNames('md:grid', {
              'md:grid-cols-16': country == 'uk',
              'md:grid-cols-12': country == 'us',
            })}>
            <div
              className={classNames('flex flex-row', {
                'justify-start md:justify-between gap-x-16 md:gap-1 md:col-span-11 flex-wrap':
                  country == 'uk',
                'justify-between gap-4 md:col-span-7': country == 'us',
              })}>
              <div>
                <h3 className="text-sm sm:text-base font-shield-black">Come Play</h3>
                <ul className="mt-4 space-y-3">
                  {navigation.play
                    .filter((item) => item.display != false)
                    .map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-xxs sm:text-sm">
                          {item.name}
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
              <div>
                <h3 className="text-sm sm:text-base font-shield-black">Company</h3>
                <ul className="mt-4 space-y-3">
                  {navigation.company
                    .filter((item) => item.display != false)
                    .map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className="text-xxs sm:text-sm"
                          target={item.external ? '_blank' : '_self'}
                          rel="noreferrer">
                          {item.name}
                        </a>
                        {item.external && (
                          <ExternalLink iconColor="text-brand-bright dark:text-accent-cyan" />
                        )}
                      </li>
                    ))}
                </ul>
              </div>
              <div
                className={classNames({
                  'mt-8 md:mt-0 w-full md:w-auto': country == 'uk',
                })}>
                <h3 className="text-sm sm:text-base font-shield-black">Legal Stuff</h3>
                <ul
                  className={classNames('mt-4', {
                    'md:space-y-0 grid grid-cols-2 gap-3': country == 'uk',
                    'space-y-3': country == 'us',
                  })}>
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        target={item.external ? '_blank' : '_self'}
                        className="text-xxs sm:text-sm"
                        rel="noreferrer">
                        {item.name}
                      </a>
                      {item.name == 'Your Privacy Choices' && (
                        <div className="inline-block h-3 ml-1.5">
                          <Img
                            src="/uploads/icons/privacyoptions-100x48.png"
                            alt="Privacy Options icon"
                            width={100}
                            height={48}
                            className="h-full w-auto"
                          />
                        </div>
                      )}
                    </li>
                  ))}
                  {country == 'uk' && (
                    <>
                      {navigation.legalUK.map((item) => (
                        <li key={item.name}>
                          <a
                            href={item.href}
                            className="text-xxs sm:text-sm"
                            target={item.external ? '_blank' : '_self'}
                            rel="noreferrer">
                            {item.name}
                          </a>
                          {item.external && (
                            <ExternalLink iconColor="text-brand-bright dark:text-accent-cyan" />
                          )}
                        </li>
                      ))}
                    </>
                  )}
                  <li>
                    <button
                      className="text-xxs sm:text-sm link text-left"
                      onClick={(): void => {
                        window.OneTrust?.ToggleInfoDisplay();
                      }}>
                      Manage Cookie Preferences
                    </button>
                  </li>
                  {country == 'us' && (
                    <>
                      <li>
                        <LocationLink
                          slug="company/privacy-policy/"
                          hash="healthprivacypolicy"
                          country={country}>
                          <a id="healthprivacy" className="text-xxs sm:text-sm">
                            Health Privacy Policy
                          </a>
                        </LocationLink>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
            <div
              className={classNames('mt-10 md:mt-0', {
                'md:col-span-4 md:col-start-13': country == 'uk',
                'text-center md:text-left md:col-span-4 md:col-start-9': country == 'us',
              })}>
              <h3 className="text-xl mb-4 font-shield-black">Sign up for offers + updates</h3>
              <p className="text-sm md:text-base mb-5">
                Yes, another email signup thingy in a website footer. But our email and text updates
                are ones you’ll <span className="whitespace-nowrap">actually read.</span>
              </p>
              <LocationLink slug="email-and-text-alerts/" country={country}>
                <a className="btn">Subscribe</a>
              </LocationLink>
            </div>
          </div>
          <div className="-mx-4 my-8 md:mt-12 md:mx-0 border-y-4 border-white dark:border-brand-navy pt-2 pb-4 md:py-4 px-2 md:px-0">
            <div className="flex flex-col md:flex-row items-center justify-between dark:link-white">
              <div>
                <ul className="flex justify-center md:justify-between items-center md:flex-1 gap-2 lg:gap-4 2xl:gap-8 mb-1 md:mb-0 md:-ml-2">
                  <li>
                    <a
                      href="//topgolf.com/"
                      className="teg-logo inline-flex p-2 border-solid border-2 border-transparent hover:border-accent-mint dark:hover:border-accent-neon">
                      <Img
                        dark="/theme/logos/logo-TG-wordmark-white.svg"
                        src="/theme/logos/logo-TG-wordmark-dark.svg"
                        alt="Topgolf Venues"
                        width={360}
                        height={45}
                        className="h-3 md:h-4 2xl:h-5 mx-auto w-auto "
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="//toptracer.com/"
                      target="_blank"
                      className="teg-logo inline-flex p-2 border-solid border-2 border-transparent hover:border-accent-mint dark:hover:border-accent-neon"
                      rel="noreferrer">
                      <Img
                        dark="/theme/logos/logo-TT-white.svg"
                        src="/theme/logos/logo-TT-dark.svg"
                        alt="Toptracer"
                        width={448}
                        height={42}
                        className="h-3 md:h-4 2xl:h-5 mx-auto w-auto"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="//topgolfmedia.com/"
                      className="teg-logo inline-flex p-2 border-solid border-2 border-transparent hover:border-accent-mint dark:hover:border-accent-neon">
                      <Img
                        dark="/theme/logos/logo-tgm-wordmark-white.svg"
                        src="/theme/logos/logo-tgm-wordmark-dark.svg"
                        alt="Topgolf Media"
                        width={438}
                        height={86}
                        className="h-4 lg:h-5 2xl:h-6 mx-auto w-auto"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="text-center md:text-right text-xxs lg:text-xs leading-snug font-shield uppercase tracking-wide">
                <div>
                  <span className="block md:inline-block">
                    ©{new Date().getFullYear()} Topgolf International, Inc.
                  </span>{' '}
                  <span className="block md:inline-block">All rights reserved.</span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <div className="flex flex-col md:flex-row items-center gap-4 md:gap-8">
              <a
                href="https://www.levelaccess.com/topgolf?utm_source=topgolfhomepage&utm_medium=iconlarge&utm_term=eachannelpage&utm_content=header&utm_campaign=topgolf"
                target="_blank"
                className="inline-block p-1 border-none"
                rel="noreferrer">
                <Img
                  src="uploads/icons/2022-LevelAccess_Logo_V1_RGB_blue.svg"
                  alt="This icon serves as a link to download the Level Access assistive technology app for individuals with physical disabilities. It is featured as part of our commitment to diversity and inclusion."
                  className="block dark:hidden h-8 mx-auto"
                  width={86}
                  height={32}
                />
                <Img
                  src="uploads/icons/2022-LevelAccess_Logo_V1_RGB_light.svg"
                  alt="This icon serves as a link to download the Level Access assistive technology app for individuals with physical disabilities. It is featured as part of our commitment to diversity and inclusion."
                  className="hidden dark:block h-8 mx-auto"
                  width={86}
                  height={32}
                />
              </a>
              <div className="switcher text-xxs flex items-center gap-1 flex-shrink-0">
                <i className="fa-solid fa-circle-half-stroke text-brand-bright dark:text-accent-cyan" />
                {theme === 'dark' && (
                  <button
                    className="text-brand hover:text-brand-bright dark:text-white dark:hover:text-accent-cyan uppercase tracking-wide"
                    onClick={(): void => setTheme('')}>
                    Decrease Contrast
                  </button>
                )}
                {(theme === '' || theme === undefined) && (
                  <button
                    className="text-brand hover:text-brand-bright dark:text-white dark:hover:text-accent-cyan uppercase tracking-wide"
                    onClick={(): void => setTheme('dark')}>
                    Increase Contrast
                  </button>
                )}
              </div>
            </div>

            <div className="text-xxs text-center md:text-left">
              <i className="fas fa-external-link-alt mr-2" aria-hidden="true" />
              Indicates link opens external site which may not meet{' '}
              <span className="whitespace-nowrap">accessibility guidelines</span>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
