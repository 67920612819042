import { FC } from 'react';

export const AiChatbot: FC = () => {
  return (
    <a
      data-sierra-chat="modal"
      href="https://sierra.chat/agent/VO5RQzQBlcVkf_8BlcVok0fRcz5nb6cYcyoRKHcRMIA/chat"
      target="_blank"
      rel="noreferrer"
      id="tgChatbot"
      className="btn outline !hidden">
      Launch Chat
    </a>
  );
};
