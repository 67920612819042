import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { FC } from 'react';

import { LocationProvider } from '../../providers/locationProvider';
import { Country, Module, VenueDetails } from '../../services/types';
import { AiChatbot } from '../elements/aiChatbot';
import { Footer } from '../footer/footer';
import { LocationLink } from '../locationLink';
import { Sidebar } from '../sidebar/sidebar';

import { AppLayout } from './appLayout';

const Notification = dynamic(() => import('./notification').then((ex) => ex.Notification), {
  ssr: false,
});

type Props = {
  title: string;
  country: Country;
  hasNavigation?: boolean;
  showNotification?: boolean;
  location?: VenueDetails;
  locationNav?: boolean;
  module?: Module;
  className?: string;
  description?: string | null;
  sharingImage?: string | null;
  cookieBanner?: boolean;
  canonical?: string | null;
  alternateUK?: string | null;
  alternateUS?: string | null;
  children?: React.ReactNode;
};

export const Layout: FC<Props> = ({
  title,
  children,
  country,
  location,
  module,
  hasNavigation = true,
  showNotification = true,
  locationNav = true,
  className = 'flex flex-col w-full',
  description = '',
  sharingImage = '',
  cookieBanner = true,
  canonical,
  alternateUK,
  alternateUS,
}) => {
  let pageTitle = `${title} | Topgolf`;
  if (country === 'uk') {
    pageTitle = `${title} | Topgolf UK`;
  }
  if (location) {
    pageTitle = `${title} | Topgolf ${location.name}`;
  }
  const loc = useRouter();

  return (
    <LocationProvider country={country} location={location}>
      <a href="#content" id="skipNav" className="btn sm skip-to-content-link">
        Skip to Content
      </a>
      {country == 'uk' && showNotification && (
        <Notification>
          <div className="text-xxs sm:text-xs">
            <span className="font-teeline-bold-italic">Our Commitment to Play Safely: </span>
            <span className="font-shield">
              <LocationLink slug="play-safely" country={country}>
                <a
                  aria-label="Learn More About Our Commitment to Play Safely"
                  className="dark:link-white whitespace-nowrap">
                  Learn More
                </a>
              </LocationLink>{' '}
              <span className="hidden sm:inline-block">about our health and safety rules.</span>
            </span>
          </div>
        </Notification>
      )}
      <AppLayout
        title={pageTitle}
        country={country}
        description={description}
        sharingImage={sharingImage}
        canonical={canonical}
        alternateUK={alternateUK}
        alternateUS={alternateUS}
        cookieBanner={cookieBanner}>
        {hasNavigation && !loc.query.app ? (
          <div className="min-h-screen lg:ml-64">
            <Sidebar module={module} showLocationNav={locationNav} />
            <div className={'flex flex-col min-h-screen'}>
              {/* <PromoBanner country={country} /> */}
              <main id="content" className={className}>
                {children}
              </main>
              <Footer country={country} />
              <AiChatbot />
            </div>
          </div>
        ) : (
          <>{children}</>
        )}
      </AppLayout>
    </LocationProvider>
  );
};
